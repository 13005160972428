<div class="card">
  <div class="card-header" style="padding-top: 10px; padding-bottom: 10px">
    <a routerLink="/client-chat" target="_blank">
      <ups-button
        label="CustomerChat"
      />
    </a>

  </div>
</div>
@if (isChatLoading()) {
  <div class="d-flex justify-content-center align-items-center" style="height: calc(95vh - 120px)">
    <spinner/>
  </div>
  <iframe
    [src]="chatAutoLoginUrl | safePath"
    style="visibility: hidden; opacity: 0; width: 0; height: 0"
  ></iframe>
} @else {
  @if (isHidden()) {
    <spinner/>
  }
  <iframe
    [src]="environment.chatUrl | safePath"
    allow="camera;microphone"
    width="100%"
    style="height: calc(95vh - 120px);"
    [style.visibility]="isHidden()?'hidden':'visible'"
  ></iframe>
}
